<div class="px-3 flex flex-col w-64">
	<pipelines-status-display-container
		[statusHistory]="statusHistory"
		[rowData]="rowData"
		[withdrawData]="withdrawData"
		[isPreSubmit]="isPreSubmit">
	</pipelines-status-display-container>
	<hr>
	<pipelines-review-reasons
		*ngIf="!withdrawData"
		[applicationType]="rowData.applicationType"
		[applicationId]="rowData.applicationId"
		[onMenuOpenedEvent]="menuOpenedEvent">
	</pipelines-review-reasons>
	<pipelines-withdrawn-comment *ngIf="withdrawData" [withdrawData]="withdrawData"></pipelines-withdrawn-comment>
</div>
