export const PersonalApplicationTerminalPhases = [
	'Denied',
	'Onboarded',
	'Archived',
	'Withdrawn',
];

export const BusinessApplicationTerminalPhases = [
	'Denied',
	'Onboarded',
	'Archived',
	'Withdrawn',
];
