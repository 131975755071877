import { Component, Input, OnInit } from '@angular/core';
import { WithdrawData } from '@pipelines/app/models/withdraw-data';
import {
	StatusDisplay
} from '@pipelines/app/components/grid/renderers/actions-renderer/actions-info-menu/status-display';
import { StatusEntry } from '@pipelines/app/models/status-entry';
import { RowDef } from '@pipelines/app/models/row-def';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';
import _ from 'lodash';
import { Subject } from 'rxjs';

@Component({
	selector: 'pipelines-actions-info-menu',
	templateUrl: './actions-info-menu.component.html',
	styleUrls: ['./actions-info-menu.component.scss']
})
export class ActionsInfoMenuComponent implements OnInit {

	@Input() public rowData: RowDef;
	@Input() public menuOpenedEvent: Subject<void>;
	public withdrawData: WithdrawData;
	public currentStatus: string;
	public statusHistory: StatusEntry[];
	public maxReviewReasonsToDisplay = 3;
	public statusDisplays: StatusDisplay[];
	public isPreSubmit: boolean;

	constructor() {
	}

	ngOnInit(): void {
		this.currentStatus = this.rowData.status;
		this.statusHistory = this.getStatusHistoryDesc();
		this.isPreSubmit = this.getIsPreSubmit();
		this.withdrawData = this.getWithdrawData();
	}

	private getIsPreSubmit(): boolean {
		let statusEntry = null;
		if (this.statusHistory) {
			statusEntry = this.statusHistory[0];
		}
		const statusName = statusEntry?.Name ?? this.rowData.status;
		return statusName === knownApplicationStatus.New || statusName === knownApplicationStatus.InProgress;
	}

	private getStatusHistoryDesc(): StatusEntry[] {
		if (this.rowData.rowSupplement.statusHistory) {
			return _.orderBy(this.rowData.rowSupplement.statusHistory, 'updateDate', 'desc');
		}
		return null;
	}

	private getWithdrawData(): WithdrawData {
		const withdrawnEntries = this.statusHistory?.filter((status) => status.name === knownApplicationStatus.Withdrawn);
		if (withdrawnEntries?.length > 0) {
			const latestEntry = withdrawnEntries?.reduce((first, second) => new Date(first.updateDate) > new Date(second.updateDate) ? first : second);
			if (latestEntry) {
				return latestEntry.additionalData as WithdrawData;
			}
		}

		return null;
	}
}
